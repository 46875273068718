import React, { useState } from 'react'
import './preSale.css'
import Footer from '../../components/footer/footer'

function PreSale() {
    const string = '0x14597d4bc5e4d9b8ce40086bb054121cb992d4b8';
    const [buttonText, setButtonText] = useState('COPY');


    const copyToClipboard = () => {
        navigator.clipboard.writeText(string)
            .then(() => {
                setButtonText('COPIED');
                setTimeout(() => {
                    setButtonText('COPY');
                }, 2000); // Reset to "COPY" after 2 seconds
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });

    };
    return (
        <div>
            <div className="presale-div">
                <div className="presale-container">

                    <div className="presale-box">
                        <div className="presale-box-left">
                            <img src={require("./tmp.webp")} alt="" srcset="" />
                        </div>
                        <div className="presale-box-right">
                            <h3>Looks familiar, Eh!</h3>
                            <p>TOKEN NAME:</p>
                            <h2>CORNHUB</h2>
                            <p>TOKEN TICKER:</p>
                            <h2>$CORNHUB</h2>
                            <p>CORNHUB token contract address will not be announced</p>

                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Circulating supply:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>2.1 Trillion CORNHUB</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px  0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Units offering:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>48% of Supply</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px  0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>LP tokens to be burned:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>100%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px  0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Investment token:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>CRO</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <h4>MINIMUM PARTICIPATION AMOUNT</h4>
                                    </div>
                                    <div className="right-text">
                                        <h4>200 CRO</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <h4>MAXIMUM PARTICIPATION AMOUNT</h4>
                                    </div>
                                    <div className="right-text">
                                        <h4>5000 CRO</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Offering starts:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>Friday, 7th June, 08:15 AM EST</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Offering ends:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>Monday, 10th June, 09:00 AM EST</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Hardcap:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>Undisclosed</p>
                                    </div>
                                </div>
                            </div>
                            Everyone who contributes after hardcap limit will receive CRO back in refund.
                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>LP launch time:</p>
                                    </div>
                                    <div className="right-text">
                                        <p>Undisclosed</p>
                                    </div>
                                </div>
                            </div>
                            <div className="presale-text" style={{ width: "100%", margin: "2px 0" }}>
                                <div className="presale-text-box">
                                    <div className="left-text">
                                        <p>Official dex:</p>
                                    </div>
                                    <div className="right-text">
                                        <p><a href='https://Ebisusbay.com' target='_blank' style={{ color: "#f90" }}>Ebisusbay.com</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="tokenid" style={{ margin: "10px" }}>
                                <h1 style={{ textAlign: "center", color: "red" }}>PRESALE ENDED</h1>
                            </div>
                            <div className="last-text">
                                <p>CRO sent to presale address after Monday 10th June, 9 am EST might not get refunded</p>
                                <p>LP will be launched on Ebisusbay.com at Undisclosed time on Monday, 10th June 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer position="relative" />
        </div>
    )
}

export default PreSale
